<template>
  <section class="settings-section section-block">
    <SectionTitle
      cyBtn="edit-time-parameter"
      title="時段設定"
      btn="編輯"
      @edit="modal.edit = true"
    />
    <div>
      <el-form label-position="left" label-width="250px">
        <el-form-item label="時段間隔(分鐘) ：" class="settings-item">
          <span data-cy="time-space-view" class="content">{{
            configData.timeUnit !== null ? configData.timeUnit : '尚未設定'
          }}</span>
        </el-form-item>

        <el-form-item label="時段偏移(分鐘) ：" class="settings-item">
          <span data-cy="time-offset-view" class="content">{{
            configData.timeUnitOffset !== null ? configData.timeUnitOffset : '尚未設定'
          }}</span>
        </el-form-item>

        <el-form-item label="最小預約時數(分鐘) ：" class="settings-item">
          <span data-cy="min-reservation-time-view" class="content">{{
            configData.minReservationInterval !== null
              ? configData.minReservationInterval
              : '尚未設定'
          }}</span>
        </el-form-item>

        <el-form-item label="最大預約時數(分鐘) ：" class="settings-item">
          <span data-cy="max-reservation-time-view" class="content">{{
            configData.maxReservationInterval ? configData.maxReservationInterval : '尚未設定'
          }}</span>
        </el-form-item>

        <el-form-item label="最晚可取消預約時間(分鐘)：" class="settings-item">
          <span data-cy="cancel-interval-view" class="content">{{
            configData.cancelInterval ? configData.cancelInterval : '尚未設定'
          }}</span>
        </el-form-item>
      </el-form>
    </div>

    <EditReservationBasicSettingsModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import EditReservationBasicSettingsModal from './EditReservationBasicSettingsModal.vue'
export default defineComponent({
  name: 'ReservationBasicSettings',
  components: {
    EditReservationBasicSettingsModal,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const modal = reactive({
      edit: false,
    })
    return { modal }
  },
})
</script>

<style lang="postcss" scoped></style>
