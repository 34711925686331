<template>
  <section class="settings-section section-block">
    <SectionTitle title="預約收款設定" :hideBtn="hideEditBtn" @edit="onEdit" />
    <el-form label-position="left" label-width="250px" :show-message="false">
      <el-form-item style="margin-bottom: 0" label="收款模式類型" :show-message="false">
        {{ displayData.chargeType }}
      </el-form-item>
      <GrayBlockContainer v-if="showDetailInfo" style="max-width: 640px">
        <InfoList
          v-if="curChargeType === 'deposit'"
          class="status-content"
          label-position="left"
          label-width="230px"
          :displayData="despoitConfigInfo"
        />
        <InfoList
          v-if="curChargeType === 'checkout'"
          class="status-content"
          label-position="left"
          label-width="230px"
          :displayData="checkoutConfigInfo"
        />
      </GrayBlockContainer>
    </el-form>

    <p v-if="curChargeType === 'checkout'" class="mt-[32px] text-sub">
      請注意：在預約結帳模式下，由於系統可提供發票開立，因此需向消費者揭露服務項目的實際金額。
    </p>
  </section>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import InfoList from '@/components/InfoList.vue'
import { get, map, join } from 'lodash'
import dayjs from 'dayjs'
import { paymentOptionsConfig } from '@/config/payment'
import { useDeposit } from '@/use/deposit'
import { useReservationCheckout } from '@/use/reservationCheckout'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ReservationPaymentSettings',
  components: { GrayBlockContainer, InfoList },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup(props) {
    const router = useRouter()
    const {
      feeModel: depositFeeModel,
      feeTarget: depositFeeTarget,
      services: depositServices,
      attachServices: depositAttachServices,
      dateRange: depositDateRange,
    } = useDeposit()
    const { feeTarget: checkoutFeeTarget } = useReservationCheckout()
    const { checkAction } = usePermissions()
    const useFeatures = computed(() => {
      return {
        deposit: checkAction('admin.appointmentDepositConfig.page'),
        checkout: checkAction('admin.appointmentCheckoutConfig.page'),
      }
    })

    const showDetailInfo = computed(() => {
      return (
        ['deposit', 'checkout'].includes(curChargeType.value) &&
        (useFeatures.value.deposit || useFeatures.value.checkout)
      )
    })

    const hideEditBtn = computed(() => {
      if (!useFeatures.value.deposit && !useFeatures.value.checkout) return true
      return false
    })

    const curChargeType = computed(() => get(props.configData, 'reservation.chargeType'))

    const chargeTypeConfig = {
      none: { label: '不收費', value: 'none' },
      deposit: { label: '訂金', value: 'deposit' },
      'deposit-disabled': { label: '訂金（未啟用）', value: 'deposit' },
      checkout: { label: '預約結帳', value: 'checkout' },
    }

    const displayData = computed(() => {
      if (curChargeType.value === 'deposit') {
        const enabled = get(props.configData, 'deposit.enable', false)
        return {
          chargeType: enabled
            ? chargeTypeConfig.deposit.label
            : chargeTypeConfig['deposit-disabled'].label,
        }
      }

      return {
        chargeType: get(chargeTypeConfig, `${curChargeType.value}.label`, '-'),
      }
    })

    const checkoutConfigInfo = computed(() => {
      const invoiceConfig = get(props.configData, 'checkoutInvoice', {})
      const payments = get(props.configData, 'checkoutPayments', {})
      return [
        { label: '收費對象', value: checkoutFeeTarget(get(props.configData, 'checkout', {})) },
        {
          label: '付款方式',
          value: join(
            map(payments, (i) => get(paymentOptionsConfig, `${i.type}.name`)),
            '、',
          ),
        },
        { label: '發票開立啟用狀態', value: invoiceConfig.enable ? '啟用' : '關閉' },
      ]
    })

    const despoitConfigInfo = computed(() => {
      const config = get(props.configData, 'deposit', {})
      const payments = get(props.configData, 'depositPayments', [])
      return [
        { label: '堂票預約免付訂金', value: config.enableClassTicketCredit ? '啟用' : '關閉' },
        { label: '收費金額設定', value: depositFeeModel(config) },
        { label: '收費對象設定', value: depositFeeTarget(config) },
        { label: '收取訂金服務項目設定', value: depositServices(config) },
        { label: '收取訂金附加服務設定', value: depositAttachServices(config) },
        { label: '收取訂金期間設定', value: depositDateRange(config) },
        {
          label: '付款方式',
          value: join(
            map(payments, (i) => get(paymentOptionsConfig, `${i.type}.name`)),
            '、',
          ),
        },
      ]
    })

    const onEdit = () => {
      console.log('edit')
      router.push({ name: 'EditReservationPaymentSettings' })
    }

    return {
      showDetailInfo,
      useFeatures,
      hideEditBtn,
      onEdit,
      displayData,
      despoitConfigInfo,
      checkoutConfigInfo,
      curChargeType,
    }
  },
})
</script>

<style scoped lang="postcss"></style>
