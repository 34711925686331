<template>
  <section :v-loading="loading" class="settings-section section-block">
    <SectionTitle title="進階服務設定" @edit="onEdit" />
    <div>
      <el-form
        :model="formData"
        label-position="left"
        label-width="250px"
        @submit.prevent.native="updateReservationParameter"
      >
        <!-- <el-form-item label="預約模式">
          {{ displayData.clientOrderMode }}
        </el-form-item>
        <el-form-item v-if="!configData.allowBatchOrder" label="多人預約單服務">
          {{ displayData.allowOrderMorePeople }}
        </el-form-item>
        <el-form-item
          v-if="formData.allowOrderMorePeople"
          label="多人預約人數上限"
        >
          {{ displayData.maxOrderMorePeopleCount }}
        </el-form-item>
        <el-form-item v-if="!configData.allowBatchOrder" label="單人預約多服務">
          {{ displayData.allowOtherReservation }}
        </el-form-item>

        <template v-if="formData.allowOtherReservation">
          <el-form-item label="其他服務預設開始時間設定">
            {{ displayData.secondOrderStartDefault }}
          </el-form-item>
        </template> -->

        <el-form-item
          v-for="struct in structData"
          :key="struct.key"
          :label="struct.label"
        >
          <div v-for="(item, idx) in struct.items" :key="`item-${idx}`">
            <p v-if="item.el === 'p'">
              {{ item.label }}
            </p>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <EditReservationAdvanceSettings
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import EditReservationAdvanceSettings from './components/EditReservationAdvanceSettings.vue'
import { UpdateReservationParameter } from '@/api/reservation'
import { mapGetters } from 'vuex'
import { computed, reactive, toRefs, watch, onMounted } from 'vue'

export default {
  name: 'ReservationAdvanceSettings',
  components: { EditReservationAdvanceSettings },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const modal = reactive({
      edit: false,
    })

    const clientOrderModeConfig = {
      normal: '基礎模式',
      otherReservation: '多服務模式',
      batchOrder: '多時段模式',
    }

    const displayData = computed(() => {
      const config = { ...props.configData }
      return {
        clientOrderMode: clientOrderModeConfig[config.clientOrderMode],
        allowOrderMorePeople: config.allowOrderMorePeople ? '開啟' : '關閉',
        maxOrderMorePeopleCount: config.maxOrderMorePeopleCount,
        allowOtherReservation: config.allowOtherReservation ? '開啟' : '關閉',
        secondOrderStartDefault: config.secondOrderStartDefault === 'continue' ? '接續第一個服務' : '與第一個服務同時',
      }
    })

    const formData = reactive({
      clientOrderMode: 'normal',
      allowOrderMorePeople: false,
      maxOrderMorePeopleCount: 1,
      allowOtherReservation: false,
      secondOrderStartDefault: '',
      secondOrderStartClientChange: false,
    })

    const loading = reactive({ value: false })

    const onEdit = () => {
      modal.edit = true
    }

    const updateReservationParameter = async () => {
      if (loading.value) return
      loading.value = true
      const form = formData
      const [, err] = await UpdateReservationParameter({
        shopId: props.shop,
        clientOrderMode: form.clientOrderMode,
        allowOrderMorePeople: form.allowOrderMorePeople,
        maxOrderMorePeopleCount: form.maxOrderMorePeopleCount || 1,
        allowOtherReservation: form.allowOtherReservation,
        secondOrderStartClientChange: form.secondOrderStartClientChange,
        secondOrderStartDefault: form.secondOrderStartDefault,
      })
      loading.value = false
      if (err) {
        emit('message', { type: 'error', message: err })
        return
      }
      emit('message', { type: 'success', message: '更新成功!' })
      emit('refresh')
    }

    const syncConfigData = () => {
      const config = { ...props.configData }
      formData.allowOrderMorePeople = config.allowOrderMorePeople
      formData.maxOrderMorePeopleCount = config.maxOrderMorePeopleCount || 1
      formData.allowOtherReservation = config.allowOtherReservation
      formData.secondOrderStartDefault = config.secondOrderStartDefault
      formData.secondOrderStartClientChange = config.secondOrderStartClientChange
    }

    watch(() => props.configData, syncConfigData, { deep: true })

    const structData = computed(() => {
      const config = { ...props.configData }
      const data = [
        {
          label: '預約模式',
          key: 'clientOrderMode',
          items: [
            { el: 'p', label: displayData.value.clientOrderMode },
          ],
        },
        {
          label: '多人預約單服務',
          key: 'allowOrderMorePeople',
          items: [
            { el: 'p', label: displayData.value.allowOrderMorePeople },
          ],
        },
        {
          label: '多人預約人數上限',
          key: 'maxOrderMorePeopleCount',
          items: [
            { el: 'p', label: displayData.value.maxOrderMorePeopleCount },
          ],
        },
        {
          label: '單人預約多服務',
          key: 'allowOtherReservation',
          items: [
            { el: 'p', label: displayData.value.allowOtherReservation },
          ],
        },
        {
          label: '其他服務預設開始時間設定',
          key: 'secondOrderStartDefault',
          items: [
            { el: 'p', label: displayData.value.secondOrderStartDefault },
          ],
        },
      ]

      if(config.clientOrderMode === 'normal') {
        return data.filter(item => ![
          'allowOtherReservation',
          'secondOrderStartDefault'
        ].includes(item.key))
      } else if(config.clientOrderMode === 'batchOrder') {
        return data.filter(item => ![
          'allowOtherReservation',
          'secondOrderStartDefault'
        ].includes(item.key))
      }

      // TODO 根據模式顯示不同的欄位

      return data
    })

    onMounted(() => {
      syncConfigData()
    })

    return {
      structData,
      formData,
      modal,
      displayData,
      loading,
      onEdit,
      updateReservationParameter,
    }
  },
  computed: {
    ...mapGetters(['shop']),
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-input {
  @apply max-w-[560px] w-full;
}
::v-deep .el-input-number {
  @apply max-w-[560px] w-full;
}
</style>
