import { get, map } from 'lodash'
import dayjs from 'dayjs'

export const useDeposit = () => {
  const feeModel = (config) => {
    let label
    if (get(config, 'feeModel') === 'serviceCount') {
      const price = get(config, 'feeModelServiceCountPrice')
      label = `固定金額：$${price}`
    }
    if (get(config, 'feeModel') === 'totalPrice') {
      const ratio = get(config, 'feeModelTotalPriceRatio')
      label = `固定比率：${ratio * 100}%`
    }
    return label
  }

  const feeTarget = (config) => {
    let label
    if (!config.enableIgnoreMTag && !config.enableSpecifyMTag) label = '所有會員'

    if (!config.enableIgnoreMTag && config.enableSpecifyMTag && config.SpecifyMTags.length) {
      const include = map(config.SpecifyMTags, 'name')
      label = `進階標籤會員 包含：${include.join('、')}`
    }

    if (config.enableIgnoreMTag && !config.enableSpecifyMTag && config.IgnoreMTags.length) {
      const exclude = map(config.IgnoreMTags, 'name')
      label = `進階標籤會員 排除：${exclude.join('、')}`
    }

    if (config.enableIgnoreMTag && config.enableSpecifyMTag) {
      const include = map(config.SpecifyMTags, 'name')
      const exclude = map(config.IgnoreMTags, 'name')
      if (include.length && exclude.length) {
        label = `進階標籤會員 包含：${include.join('、')}｜排除：${exclude.join('、')}`
      }
      if (!include.length && exclude.length) {
        label = `進階標籤會員 排除：${exclude.join('、')}`
      }
      if (include.length && !exclude.length) {
        label = `進階標籤會員 包含：${include.join('、')}`
      }
    }
    return label
  }

  const services = (config) => {
    let label
    if (
      config.enableIgnoreService &&
      !config.enableSpecifyService &&
      !config.SpecifyServices.length
    ) {
      label = '所有服務'
    }
    if (
      config.enableIgnoreService &&
      !config.enableSpecifyService &&
      config.IgnoreServices.length
    ) {
      const exclude = map(config.IgnoreServices, 'name')
      label = `全部服務但排除：${exclude.join('、')}`
    }
    if (
      !config.enableIgnoreService &&
      config.enableSpecifyService &&
      config.SpecifyServices.length
    ) {
      const include = map(config.SpecifyServices, 'name')
      label = `特定服務：${include.join('、')}`
    }
    if (!config.enableSpecifyService && !config.enableIgnoreService) {
      label = '尚未設定'
    }

    return label
  }

  const attachServices = (config) => {
    let label
    if (
      !config.enableIgnoreServiceAttach &&
      config.enableSpecifyServiceAttach &&
      !config.SpecifyServiceAttaches.length
    ) {
      label = '全部皆免收定金'
    }
    if (
      config.enableIgnoreServiceAttach &&
      !config.enableSpecifyServiceAttach &&
      !config.IgnoreServiceAttaches.length
    ) {
      label = '所有附加服務'
    }

    if (
      config.enableIgnoreServiceAttach &&
      !config.enableSpecifyServiceAttach &&
      config.IgnoreServiceAttaches.length
    ) {
      const exclude = map(config.IgnoreServiceAttaches, 'name')
      label = `全部服務但排除：${exclude.join('、')}`
    }

    if (
      !config.enableIgnoreServiceAttach &&
      config.enableSpecifyServiceAttach &&
      config.SpecifyServiceAttaches.length
    ) {
      const include = map(config.SpecifyServiceAttaches, 'name')
      label = `特定服務：${include.join('、')}`
    }
    if (!config.enableIgnoreServiceAttach && !config.enableSpecifyServiceAttach) {
      label = '尚未設定'
    }
    return label
  }
  const dateRange = (config) => {
    if (!config.enableSpecifyTimeRange) return '關閉'
    if (!config.specifyTimeRangeStart && !config.specifyTimeRangeEnd) return '關閉'
    const formatPattern = 'YYYY/MM/DD HH:mm'
    const start = dayjs(config.specifyTimeRangeStart).format(formatPattern)
    const end = dayjs(config.specifyTimeRangeEnd).format(formatPattern)
    return `${start} ~ ${end}`
  }

  return {
    feeModel,
    feeTarget,
    services,
    attachServices,
    dateRange,
  }
}
