<template>
  <section class="section-block">
    <SectionTitle title="樣式與標題設定" btn="編輯" @edit="modal.editTheme = true" />
    <div>
      <el-form label-position="left" label-width="250px">
        <el-form-item label="樣式" class="settings-item">
          <span class="content">
            {{ pageStyleName[configData.pageStyle] || '-' }}
          </span>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <div class="mb-4"><span>內容</span></div>
      <!-- ANCHOR 預約文字表格 -->
      <el-table :data="pageTextTable" empty-text="暫無數據">
        <el-table-column prop="name" label="標題預設名稱" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.defaultValue }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="標題調整名稱" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.value }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <div class="table-edit-btn-group">
              <el-button type="text" class="table-opt-edit" @click="onEditPageText(scope.row.key)">
                編輯
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <EditReservationThemeModal
      v-if="modal.editTheme"
      :configData="configData"
      @close="modal.editTheme = false"
      @refresh="$emit('refresh')"
    />
    <EditReservationCustomTextModal
      v-if="modal.editText"
      :configData="configData"
      :editTextKey="editTextKey"
      @close="modal.editText = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import { pageStyleName, pageTextDefault } from '@/config/reservation'
import { get } from 'lodash'
import EditReservationThemeModal from './EditReservationThemeModal.vue'
import EditReservationCustomTextModal from './EditReservationCustomTextModal.vue'

export default defineComponent({
  name: 'ReservationCustomTextSettings',
  components: { EditReservationThemeModal, EditReservationCustomTextModal },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const editTextKey = ref('')
    const modal = reactive({
      editText: false,
      editTheme: false,
    })
    const pageTextTable = computed(() => {
      const pageText = get(props.configData, 'pageText') || {}
      return pageTextDefault.map((item) => {
        return {
          key: item.key,
          defaultValue: item.default,
          value: pageText[item.key] || '-',
        }
      })
    })

    const onEditPageText = (textKey) => {
      editTextKey.value = textKey
      modal.editText = true
    }

    return { pageStyleName, pageTextTable, editTextKey, onEditPageText, modal }
  },
})
</script>

<style lang="postcss" scoped></style>
